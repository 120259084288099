// frontend/src/components/ForgotPasswordDialog.js
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@mui/material';
import api from '../services/api';

const ForgotPasswordDialog = ({ open, onClose }) => {
  const [step, setStep] = useState(1);
  const [phone, setPhone] = useState('961');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (!open) {
      // Reset everything
      setStep(1);
      setPhone('961');
      setCode('');
      setNewPassword('');
      setError('');
    }
  }, [open]);

  const handleSendCode = async () => {
    setError('');
    if (!phone.startsWith('961') || phone.length < 11) {
      setError('Invalid phone number. Must start with 961 and be 11-12 digits.');
      return;
    }
    try {
      await api.post('/users/forgot_password_send_code/', { phone_number: phone });
      setStep(2);
    } catch (err) {
      setError('Could not send reset code. Check phone or try again.');
    }
  };

  const handleVerifyCode = async () => {
    setError('');
    if (!code) {
      setError('Enter the code you received.');
      return;
    }
    try {
      await api.post('/users/forgot_password_verify_code/', {
        phone_number: phone,
        code
      });
      setStep(3);
    } catch (err) {
      setError('Invalid or expired code.');
    }
  };

  const handleResetPassword = async () => {
    setError('');
    if (!newPassword || newPassword.length < 6) {
      setError('New password must be at least 6 characters.');
      return;
    }
    try {
      await api.post('/users/reset_password/', {
        phone_number: phone,
        new_password: newPassword
      });
      alert('Password reset successful! Please login with your new password.');
      onClose();
    } catch (err) {
      setError('Failed to reset password. Make sure you verified the code.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Forgot Password</DialogTitle>
      <DialogContent>
        {error && <p style={{ color: 'red' }}>{error}</p>}

        {step === 1 && (
          <>
            <TextField
              label="Phone Number (961...)"
              fullWidth
              margin="dense"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: '1em' }}
              onClick={handleSendCode}
            >
              Send Code
            </Button>
          </>
        )}

        {step === 2 && (
          <>
            <TextField
              label="Verification Code"
              fullWidth
              margin="dense"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: '1em' }}
              onClick={handleVerifyCode}
            >
              Verify Code
            </Button>
          </>
        )}

        {step === 3 && (
          <>
            <TextField
              label="New Password"
              type="password"
              fullWidth
              margin="dense"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: '1em' }}
              onClick={handleResetPassword}
            >
              Reset Password
            </Button>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ForgotPasswordDialog;
