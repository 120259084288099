// frontend/src/components/RegisterLoginDialog.js
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { Link } from 'react-router-dom'; // For linking to your TermsOfService route
import api from '../services/api';

const GOVERNORATES = [
  'Beirut',
  'Mount Lebanon',
  'North Lebanon',
  'Akkar',
  'Baalbek-Hermel',
  'Bekaa',
  'South Lebanon',
  'Nabatieh',
  'Keserwan',
];

const RegisterLoginDialog = ({ open, onClose, onForgotPassword }) => {
  const [mode, setMode] = useState('register');

  // ------ Register states ------
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('961'); // start with 961 for sign-up
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [governorate, setGovernorate] = useState(GOVERNORATES[0]);
  const [registerCode, setRegisterCode] = useState('');
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [registerError, setRegisterError] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false); // New for TOS

  // ------ Login states ------
  const [phoneOrEmail, setPhoneOrEmail] = useState('961');
  const [loginPassword, setLoginPassword] = useState('');
  const [loginError, setLoginError] = useState('');

  useEffect(() => {
    if (!open) {
      // Reset everything on dialog close
      setMode('register');
      setFirstName('');
      setLastName('');
      setPhone('961');
      setEmail('');
      setPassword('');
      setGovernorate(GOVERNORATES[0]);
      setRegisterCode('');
      setIsPhoneVerified(false);
      setRegisterError('');
      setTermsAccepted(false);

      // Pre-fill login with '961' again each time the dialog reopens
      setPhoneOrEmail('961');
      setLoginPassword('');
      setLoginError('');
    }
  }, [open]);

  // ----------------- REGISTER FLOW -----------------
  const validateRegisterFields = () => {
    if (!firstName.trim()) return 'First name is required.';
    if (!lastName.trim()) return 'Last name is required.';
    if (!phone.startsWith('961') || phone.length < 10) {
      return 'Phone must start with "961" and be valid.';
    }
    if (!password || password.length < 6) {
      return 'Password must be at least 6 characters.';
    }
    return null;
  };

  const handleSendRegCode = async () => {
    const err = validateRegisterFields();
    if (err) {
      setRegisterError(err);
      return;
    }
    try {
      setRegisterError('');
      await api.post('/users/send_verification_code/', {
        phone_number: phone,
      });
      alert('Verification code sent to your phone.');
    } catch (error) {
      setRegisterError('Failed to send verification code. Phone might be in use or invalid.');
    }
  };

  const handleVerifyRegCode = async () => {
    if (!registerCode) {
      setRegisterError('Please enter the code you received.');
      return;
    }
    try {
      setRegisterError('');
      await api.post('/users/verify_phone_code/', {
        phone_number: phone,
        code: registerCode,
      });
      setIsPhoneVerified(true);
      alert('Phone verified. You can now complete registration.');
    } catch (error) {
      setRegisterError('Invalid or expired code.');
    }
  };

  const handleRegisterSubmit = async () => {
    // Must accept TOS first
    if (!termsAccepted) {
      setRegisterError('You must agree to the Terms of Service to register.');
      return;
    }
    if (!isPhoneVerified) {
      setRegisterError('Please verify your phone first by sending and verifying the code.');
      return;
    }
    try {
      setRegisterError('');
      const username = `${firstName}_${lastName}`; // or any unique logic
      const response = await api.post('/users/register/', {
        username,
        email,
        password,
        phone,
        first_name: firstName,
        last_name: lastName,
        governorate,
      });
      const { token, user } = response.data;
      localStorage.setItem('token', token);
      localStorage.setItem('username', user.username);
      if (user.is_supplier) {
        localStorage.setItem('s', true);
      }
      window.location.reload();
    } catch (err) {
      setRegisterError('Registration failed. Possibly phone or email already in use.');
    }
  };

  // ----------------- LOGIN FLOW -----------------
  const handleLoginSubmit = async () => {
    if (!phoneOrEmail || !loginPassword) {
      setLoginError('Please enter phone/email and password.');
      return;
    }
    try {
      setLoginError('');
      const response = await api.post('/users/login/', {
        username: phoneOrEmail,
        password: loginPassword,
      });
      const { token, user } = response.data;
      localStorage.setItem('token', token);
      localStorage.setItem('username', user.username);
      if (user.is_supplier) {
        localStorage.setItem('s', true);
      }
      window.location.reload();
    } catch (error) {
      setLoginError('Invalid phone/email or password.');
    }
  };

  const dialogTitle = mode === 'register' ? 'Sign Up' : 'Login';

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        {/* -------- REGISTER FORM -------- */}
        {mode === 'register' && (
          <>
            {registerError && <p style={{ color: 'red' }}>{registerError}</p>}

            <TextField
              label="First Name"
              fullWidth
              margin="dense"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              label="Last Name"
              fullWidth
              margin="dense"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              label="Phone Number (961...)"
              fullWidth
              margin="dense"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <TextField
              label="Email (optional)"
              fullWidth
              margin="dense"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <FormControl fullWidth margin="dense">
              <InputLabel>Governorate</InputLabel>
              <Select
                value={governorate}
                label="Governorate"
                onChange={(e) => setGovernorate(e.target.value)}
              >
                {GOVERNORATES.map((gov) => (
                  <MenuItem key={gov} value={gov}>
                    {gov}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Password"
              type="password"
              fullWidth
              margin="dense"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            {/* Send Code + Verify Code */}
            <div style={{ marginTop: '1em' }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleSendRegCode}
                style={{ marginRight: '0.5em' }}
              >
                Send Code
              </Button>
              <TextField
                label="Enter Code"
                size="small"
                style={{ width: '80px', marginRight: '0.5em' }}
                value={registerCode}
                onChange={(e) => setRegisterCode(e.target.value)}
              />
              <Button
                variant="outlined"
                color="primary"
                onClick={handleVerifyRegCode}
              >
                Verify Code
              </Button>
            </div>
            <p style={{ fontSize: '0.8rem', color: isPhoneVerified ? 'green' : 'red' }}>
              Phone Verified: {isPhoneVerified ? 'Yes' : 'No'}
            </p>

            {/* Terms of Service Checkbox */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  color="primary"
                />
              }
              label={
                <>
                  I agree to the{' '}
                  <Link to="/terms-of-service/" target="_blank" rel="noopener noreferrer">
                    Terms of Service
                  </Link>
                </>
              }
              style={{ marginTop: '1em' }}
            />

            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: '1em' }}
              onClick={handleRegisterSubmit}
            >
              Register
            </Button>

            <p style={{ marginTop: '1em' }}>
              Already have an account?{' '}
              <span
                onClick={() => setMode('login')}
                style={{ color: 'blue', cursor: 'pointer' }}
              >
                Login
              </span>
            </p>
          </>
        )}

        {/* -------- LOGIN FORM -------- */}
        {mode === 'login' && (
          <>
            {loginError && <p style={{ color: 'red' }}>{loginError}</p>}

            <TextField
              label="Phone or Email (Default: 961...)"
              fullWidth
              margin="dense"
              value={phoneOrEmail}
              onChange={(e) => setPhoneOrEmail(e.target.value)}
              helperText="Remove '961' if logging in with email."
            />
            <TextField
              label="Password"
              type="password"
              fullWidth
              margin="dense"
              value={loginPassword}
              onChange={(e) => setLoginPassword(e.target.value)}
            />

            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: '1em' }}
              onClick={handleLoginSubmit}
            >
              Login
            </Button>

            <p style={{ marginTop: '1em' }}>
              Forgot your password?{' '}
              <span
                onClick={() => onForgotPassword()}
                style={{ color: 'blue', cursor: 'pointer' }}
              >
                Reset here
              </span>
            </p>

            <p style={{ marginTop: '1em' }}>
              Don’t have an account?{' '}
              <span
                onClick={() => setMode('register')}
                style={{ color: 'blue', cursor: 'pointer' }}
              >
                Register
              </span>
            </p>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RegisterLoginDialog;
