import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  MenuItem,
  Snackbar,
  Alert,
  Rating
} from "@mui/material";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import api, { MainUrl } from "../services/api";
import {
  FaHeart,
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaClock,
  FaUsers,
  FaDollarSign,
  FaExclamationCircle,
  FaVolumeUp,
  FaCheckCircle,
  FaTimesCircle,
  FaListUl,
  FaArrowUp,
  FaArrowDown
} from "react-icons/fa";
import ImageCatalog from "./ImageCatalog";
import PackageDatePicker from "./booking/PackageDatePicker";
import "./Details.css";

const PACKAGE_CONTENT_TYPE_ID = 32;

const PackageDetails = () => {
  const { id } = useParams();
  const loggedInUser = localStorage.getItem("username");

  const [pkg, setPkg] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [selectedPackageDay, setSelectedPackageDay] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const [imageLinks, setImageLinks] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [availableDates, setAvailableDates] = useState([]);
  const [loginPrompt, setLoginPrompt] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [userRating, setUserRating] = useState(0);
  const [userReviewText, setUserReviewText] = useState("");

  // Collapsible sections
  const [itineraryOpen, setItineraryOpen] = useState(true);
  const [faqOpen, setFaqOpen] = useState(true);

  useEffect(() => {
    const fetchPackage = async () => {
      try {
        const response = await api.get(`/api/package/${id}/`);
        setPkg(response.data);
        setImageLinks(response.data.catalogs.map((c) => `${MainUrl}${c.image}`));

        if (loggedInUser) {
          const favResp = await api.get(`/api/favorite-package/${id}/`);
          setIsFavorite(favResp.data.is_favorite);
        }
      } catch (error) {
        console.error("Failed to fetch package details", error);
      }
    };
    fetchPackage();
  }, [id, loggedInUser]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const res = await api.get(
          `/api/reviews/?content_type=${PACKAGE_CONTENT_TYPE_ID}&object_id=${id}`
        );
        const data = res.data.results || res.data;
        setReviews(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error("Failed to fetch reviews", error);
      }
    };
    fetchReviews();
  }, [id]);

  useEffect(() => {
    if (selectedOffer) {
      const fetchPackageDays = async () => {
        try {
          const response = await api.get(`/api/packagedays/${selectedOffer.id}/`);
          setAvailableDates(response.data);
        } catch (error) {
          console.error("Failed to fetch package days", error);
        }
      };
      fetchPackageDays();
    }
  }, [selectedOffer]);

  useEffect(() => {
    if (selectedPackageDay) {
      const price = parseFloat(selectedPackageDay.package_offer.price);
      setTotalPrice(price * quantity);
    }
  }, [quantity, selectedPackageDay]);

  const handleBookAndPay = async () => {
    if (!loggedInUser) {
      setLoginPrompt(true);
      return;
    }
    if (!selectedPackageDay) {
      alert("Select a date first.");
      return;
    }
    try {
      const bookingDetails = {
        booking_type: "package",
        booking_id: selectedOffer.id,
        quantity,
        start_date: selectedPackageDay.day,
        end_date: selectedPackageDay.day
      };
      const response = await api.post("/api/initiate-payment/", bookingDetails);
      const { session } = response.data;

      if (window.Checkout) {
        window.Checkout.configure({ session: { id: session.id } });
        window.Checkout.showPaymentPage();
      } else {
        alert("Payment unavailable.");
      }
    } catch (error) {
      console.error("Payment initiation failed:", error);
      alert("Payment failed, try again.");
    }
  };

  const toggleFavorite = async () => {
    if (!loggedInUser) {
      setLoginPrompt(true);
      return;
    }
    try {
      if (isFavorite) await api.delete(`/api/favorite-package/${id}/`);
      else await api.post(`/api/favorite-package/${id}/`);
      setIsFavorite(!isFavorite);
    } catch (error) {
      console.error("Favorite toggle error", error);
    }
  };

  const handleOfferChange = (e) => {
    const offerId = parseInt(e.target.value, 10);
    const offer = pkg.offers.find((o) => o.id === offerId);
    setSelectedOffer(offer);
    setSelectedPackageDay(null);
    setAvailableDates([]);
    setQuantity(1);
    setTotalPrice(0);
  };

  const handleDateChange = (date) => {
    if (!loggedInUser) {
      setLoginPrompt(true);
      return;
    }
    const packageDay = availableDates.find(
      (day) => format(new Date(day.day), "yyyy-MM-dd") === date
    );
    setSelectedPackageDay(packageDay);
    setQuantity(1);
    setTotalPrice(0);
  };

  const handleReviewSubmit = async () => {
    if (!loggedInUser) {
      setLoginPrompt(true);
      return;
    }
    try {
      const data = {
        content_type: PACKAGE_CONTENT_TYPE_ID,
        object_id: parseInt(id, 10),
        rating: userRating,
        text: userReviewText
      };
      await api.post("/api/reviews/", data);
      setUserRating(0);
      setUserReviewText("");
      const res = await api.get(
        `/api/reviews/?content_type=${PACKAGE_CONTENT_TYPE_ID}&object_id=${id}`
      );
      const newData = res.data.results || res.data;
      setReviews(Array.isArray(newData) ? newData : []);
    } catch (error) {
      console.error("Review error:", error);
      alert("Submit review failed.");
    }
  };

  if (!pkg) return <div className="loading">Loading...</div>;

  return (
    <div className="page-container">
      <Snackbar
        open={loginPrompt}
        autoHideDuration={3000}
        onClose={() => setLoginPrompt(false)}
      >
        <Alert onClose={() => setLoginPrompt(false)} severity="warning">
          Please log in to continue.
        </Alert>
      </Snackbar>

      <div className="header-section">
        <h1 className="main-title">{pkg.title}</h1>
        <FaHeart
          className={`fav-icon ${isFavorite ? "active" : ""}`}
          onClick={toggleFavorite}
        />
      </div>

      <div className="top-section">
        <div className="left-column">
          <img
            src={`${MainUrl}/${pkg.image}`}
            alt={pkg.title}
            className="main-image"
          />
          <ImageCatalog imageLinks={imageLinks} />
        </div>
        <div className="right-column">
          <div className="booking-panel">
            <h2>Book This Package</h2>
            <TextField
              select
              label="Select Offer"
              value={selectedOffer?.id || ""}
              onChange={handleOfferChange}
              fullWidth
              margin="normal"
            >
              <MenuItem value="">Select Offer</MenuItem>
              {pkg.offers.map((offer) => (
                <MenuItem key={offer.id} value={offer.id}>
                  {offer.title} - ${offer.price}
                </MenuItem>
              ))}
            </TextField>
            {selectedOffer && (
              <PackageDatePicker
                availablePackageDays={availableDates.map((day) =>
                  format(new Date(day.day), "yyyy-MM-dd")
                )}
                onDateChange={handleDateChange}
              />
            )}
            {selectedPackageDay && selectedPackageDay.package_offer && (
              <>
                <TextField
                  type="number"
                  label="Quantity"
                  value={quantity}
                  onChange={(e) =>
                    setQuantity(
                      Math.min(
                        Math.max(parseInt(e.target.value, 10), 1),
                        selectedPackageDay.stock
                      )
                    )
                  }
                  InputProps={{
                    inputProps: { min: 1, max: selectedPackageDay.stock }
                  }}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
                <p className="price-label">
                  <strong>Total Price:</strong> ${totalPrice.toFixed(2)}
                </p>
                <Button
                  onClick={handleBookAndPay}
                  variant="contained"
                  sx={{
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#1565c0" }
                  }}
                >
                  Book & Pay
                </Button>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="info-section">
        <div className="detail-box">
          <p className="description">{pkg.description}</p>
          <p>
            <FaDollarSign /> <strong>Starting Price:</strong> $
            {pkg.offers[0].price}
          </p>
          <p>
            <FaCalendarAlt /> <strong>Available from:</strong>{" "}
            {pkg.available_from} &nbsp;
            <FaCalendarAlt /> <strong>to:</strong> {pkg.available_to}
          </p>
          <p>
            <FaClock /> <strong>Pickup time:</strong> {pkg.pickup_time}
          </p>
          <p>
            <FaClock /> <strong>Dropoff time:</strong> {pkg.dropoff_time}
          </p>
          <p>
            <FaCalendarAlt /> <strong>Period:</strong> {pkg.period} days &{" "}
            {pkg.period - 1} nights
          </p>
          <p>
            <FaVolumeUp /> <strong>Languages:</strong> {pkg.languages}
          </p>
          <p>
            <FaUsers /> <strong>Minimum age:</strong> {pkg.min_age}
          </p>
          <p>
            <FaExclamationCircle /> <strong>Cancellation:</strong>{" "}
            {pkg.cancellation_policy}
          </p>

          <div className="included-excluded-area">
            <div>
              <h4>Included</h4>
              <ul>
                {pkg.included_items.map((item) => (
                  <li key={item.id}>
                    <FaCheckCircle className="check-icon" /> {item.include}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h4>Not Included</h4>
              <ul>
                {pkg.excluded_items.map((item) => (
                  <li key={item.id}>
                    <FaTimesCircle className="times-icon" /> {item.Exclude}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="location-box">
            <FaMapMarkerAlt /> <strong>Location:</strong>
            <div
              dangerouslySetInnerHTML={{ __html: pkg.pickup_location }}
              className="map-area"
            />
          </div>
        </div>

        <div className="collapsible-section">
          <div
            className="collapsible-header"
            onClick={() => setItineraryOpen(!itineraryOpen)}
          >
            <h3>
              Itinerary <FaListUl />
            </h3>
            {itineraryOpen ? <FaArrowUp /> : <FaArrowDown />}
          </div>
          {itineraryOpen && (
            <div className="collapsible-content">
              {pkg.itinerary.map((step) => (
                <div key={step.id} className="itinerary-step">
                  <h4>{step.title}</h4>
                  <p>{step.activity}</p>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="collapsible-section">
          <div className="collapsible-header" onClick={() => setFaqOpen(!faqOpen)}>
            <h3>FAQs</h3>
            {faqOpen ? <FaArrowUp /> : <FaArrowDown />}
          </div>
          {faqOpen && (
            <div className="collapsible-content">
              {pkg.faqs.map((faq) => (
                <div key={faq.id} className="faq-item">
                  <p>
                    <strong>Q:</strong> {faq.question}
                  </p>
                  <p>
                    <strong>A:</strong> {faq.answer}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="reviews-section">
        <h3>Reviews</h3>
        {reviews.length > 0 ? (
          reviews.map((rev) => (
            <div key={rev.id} className="single-review">
              <div className="review-header">
                <strong>{rev.username}</strong>
                <Rating value={rev.rating} readOnly size="small" />
              </div>
              <p>{rev.text}</p>
              <hr />
            </div>
          ))
        ) : (
          <p>No reviews yet</p>
        )}

        <h4>Leave a Review</h4>
        <div className="review-form">
          <Rating
            name="package-rating"
            value={userRating}
            onChange={(_, newValue) => setUserRating(newValue)}
            size="medium"
          />
          <TextField
            label="Share your experience"
            multiline
            rows={4}
            value={userReviewText}
            onChange={(e) => setUserReviewText(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <Button variant="contained" onClick={handleReviewSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PackageDetails;
