// frontend/src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Button, Avatar,
         Menu, MenuItem, Badge, Dialog, DialogTitle,
         DialogContent, DialogActions, TextField } from '@mui/material';
import { styled } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router-dom';
import './Navbar.css'

import api from '../services/api';
import RegisterLoginDialog from './RegisterLoginDialog';
import ForgotPasswordDialog from './ForgotPasswordDialog';

const Logo = styled('img')({
  cursor: 'pointer',
  height: '50px',
});

const Navbar = () => {
  const navigate = useNavigate();

  const [loggedInUser, setLoggedInUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [mobileAnchorEl, setMobileAnchorEl] = useState(null);

  // Dialog states
  const [openRegisterLogin, setOpenRegisterLogin] = useState(false);
  const [openForgot, setOpenForgot] = useState(false);

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setLoggedInUser(storedUsername);
      fetchNotifications();
    }
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await api.get('/api/notifications/');
      setNotifications(response.data);
      setUnreadCount(response.data.filter(n => !n.read).length);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('s');
    api.defaults.headers.common['Authorization'] = '';
    window.location.href = '/';
  };

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleNotificationOpen = async (event) => {
    setNotificationAnchorEl(event.currentTarget);
    try {
      const unreadNotifications = notifications.filter((n) => !n.read);
      for (const notification of unreadNotifications) {
        await api.put(`/api/readnotification/${notification.id}/`);
      }
      fetchNotifications();
    } catch (err) {
      console.error('Error marking notifications as read:', err);
    }
  };
  const handleNotificationClose = () => setNotificationAnchorEl(null);

  const handleMobileMenuOpen = (event) => setMobileAnchorEl(event.currentTarget);
  const handleMobileMenuClose = () => setMobileAnchorEl(null);

  const handleProfileOpen = () => navigate('/profile');
  const handleBookingOpen = () => {
    const suppliertag = localStorage.getItem('s');
    if (suppliertag) {
      navigate('/supplier-bookings');
    } else {
      navigate('/bookings');
    }
  };
  const handleFavoritesOpen = () => navigate('/favorites/');
  const handleAboutUs = () => navigate('/about-us/');
  
  // Desktop nav items
  const mainNavItems = (
    <div className="desktop-menu">
      <Button color="inherit" onClick={() => navigate('/')}>Home</Button>
      <Button color="inherit" onClick={() => navigate('/all-packages')}>Packages</Button>
      <Button color="inherit" onClick={() => navigate('/all-tours')}>Tours</Button>
      <Button color="inherit" onClick={() => navigate('/all-activities')}>Activities</Button>
      <Button color="inherit" onClick={() => navigate('/blog')}>Blog</Button>
      <Button color="inherit" onClick={handleAboutUs}>About Us</Button>
      <Button color="inherit" onClick={() => navigate('/contact-us')}>Contact Us</Button>
    </div>
  );

  return (
    <AppBar position="static" style={{ background: 'white', padding: '20px' }}>
      <Toolbar className="navbar-toolbar">
        <Logo
          onClick={() => navigate('/')}
          className="lb-logo"
          src="/lebadvisor_logo.png"
          alt="LebAdvisor Logo"
        />

        {/* Mobile menu button */}
        <IconButton className="mobile-menu-button" onClick={handleMobileMenuOpen}>
          <MenuIcon className="burger-icon" />
        </IconButton>

        {/* Mobile menu items */}
        <Menu
          className="nav-menu"
          anchorEl={mobileAnchorEl}
          open={Boolean(mobileAnchorEl)}
          onClose={handleMobileMenuClose}
        >
          <MenuItem onClick={() => { navigate('/'); handleMobileMenuClose(); }}>Home</MenuItem>
          <MenuItem onClick={() => { navigate('/all-packages'); handleMobileMenuClose(); }}>Packages</MenuItem>
          <MenuItem onClick={() => { navigate('/all-tours'); handleMobileMenuClose(); }}>Tours</MenuItem>
          <MenuItem onClick={() => { navigate('/all-activities'); handleMobileMenuClose(); }}>Activities</MenuItem>
          <MenuItem onClick={() => { navigate('/blog'); handleMobileMenuClose(); }}>Blog</MenuItem>
          <MenuItem onClick={() => { handleAboutUs(); handleMobileMenuClose(); }}>About Us</MenuItem>
          <MenuItem onClick={() => { navigate('/contact-us'); handleMobileMenuClose(); }}>Contact Us</MenuItem>
        </Menu>

        {/* Desktop nav */}
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
          {mainNavItems}
        </div>

        {/* Right side */}
        {loggedInUser ? (
          <>
            <IconButton
              className="notification-button"
              onClick={handleNotificationOpen}
              sx={{ marginRight: '10px' }}
            >
              <Badge badgeContent={unreadCount} color="error">
                <NotificationsIcon className="not-icon" />
              </Badge>
            </IconButton>

            <IconButton onClick={handleMenu}>
              <Avatar className="user-icon">{loggedInUser[0]?.toUpperCase() || ''}</Avatar>
            </IconButton>
            <Menu
              className="nav-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleProfileOpen}>Profile</MenuItem>
              <MenuItem onClick={handleBookingOpen}>Bookings</MenuItem>
              <MenuItem onClick={handleFavoritesOpen}>Favorites</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>

            <Menu
              className="nav-menu"
              anchorEl={notificationAnchorEl}
              open={Boolean(notificationAnchorEl)}
              onClose={handleNotificationClose}
            >
              {notifications
                .sort((a, b) => {
                  // Sort unread first, then date desc
                  if (a.read === b.read) {
                    return new Date(b.created_at) - new Date(a.created_at);
                  }
                  return a.read ? 1 : -1;
                })
                .slice(0, 10)
                .map((notification) => (
                  <MenuItem
                    key={notification.id}
                    onClick={() => navigate('/bookings')}
                    className="menu-item"
                  >
                    {notification.message}
                  </MenuItem>
                ))}
              <MenuItem
                onClick={() => navigate('/notifications')}
                className="menu-item read-all"
                style={{ color: 'darkorange', fontWeight: 'bold' }}
              >
                Read all
              </MenuItem>
            </Menu>
          </>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenRegisterLogin(true)}
          >
            Sign Up / Login
          </Button>
        )}
      </Toolbar>

      {/* Register / Login Dialog */}
      <RegisterLoginDialog
        open={openRegisterLogin}
        onClose={() => setOpenRegisterLogin(false)}
        onForgotPassword={() => {
          // close the Register/Login dialog, open Forgot
          setOpenRegisterLogin(false);
          setOpenForgot(true);
        }}
      />

      {/* Forgot Password Dialog */}
      <ForgotPasswordDialog
        open={openForgot}
        onClose={() => setOpenForgot(false)}
      />
    </AppBar>
  );
};

export default Navbar;
