// src/components/AllActivities.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import Card from '../Card';
import './AllActivities.css';  // separate CSS

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@mui/material';

const AllActivities = () => {
  const [activities, setActivities] = useState([]);

  // Renaming fields to match your "Theme, Type, Governorate, Province" language
  const [themes, setThemes] = useState([]);        // categories
  const [types, setTypes] = useState([]);          // subCategories
  const [governorates, setGovernorates] = useState([]); 
  const [provinces, setProvinces] = useState([]);  

  // selected filters: none checked => show all
  const [selectedThemes, setSelectedThemes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedGovernorate, setSelectedGovernorate] = useState(0);
  const [selectedProvince, setSelectedProvince] = useState(0);

  // for "Load More" toggles
  const [showAllThemes, setShowAllThemes] = useState(false);
  const [showAllTypes, setShowAllTypes] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch everything in parallel
        const [actsRes, catRes, locRes, subCatRes, subLocRes] = await Promise.all([
          api.get('/api/all-activities/'),
          api.get('/api/categories/'),
          api.get('/api/locations/'),
          api.get('/api/subcategories/'),
          api.get('/api/sublocations/')
        ]);

        setActivities(actsRes.data);
        setThemes(catRes.data);
        setGovernorates(locRes.data);
        setTypes(subCatRes.data);
        setProvinces(subLocRes.data);

        // no default checks => show all
        setSelectedThemes([]);
        setSelectedTypes([]);
        setSelectedGovernorate(0);
        setSelectedProvince(0);
      } catch (error) {
        console.error('Failed to fetch data for AllActivities:', error);
      }
    };
    fetchData();
  }, []);

  const handleCardClick = (id) => {
    navigate(`/activity-details/${id}`);
  };

  // Toggle theme
  const handleThemeChange = (evt) => {
    const themeId = parseInt(evt.target.value);
    if (selectedThemes.includes(themeId)) {
      setSelectedThemes(selectedThemes.filter(id => id !== themeId));
    } else {
      setSelectedThemes([...selectedThemes, themeId]);
    }
  };

  // Toggle type
  const handleTypeChange = (typeId) => {
    if (selectedTypes.includes(typeId)) {
      setSelectedTypes(selectedTypes.filter(id => id !== typeId));
    } else {
      setSelectedTypes([...selectedTypes, typeId]);
    }
  };

  // Governorate selection
  const handleGovernorateChange = (evt) => {
    setSelectedGovernorate(parseInt(evt.target.value));
    setSelectedProvince(0); // Reset province if gov changes
  };

  // Province selection
  const handleProvinceChange = (evt) => {
    setSelectedProvince(parseInt(evt.target.value));
  };

  // Filter logic
  const filteredActivities = activities.filter(item => {
    // item.subcategories (not sub_categories) 
    // item.sublocations (not sub_locations)

    // 1) Themes => if user picked some, item.categories must match at least one
    if (selectedThemes.length > 0) {
      if (!selectedThemes.some(tId => item.categories.includes(tId))) {
        return false;
      }
    }
    // 2) Types => if user picked some, item.subcategories must match at least one
    if (selectedTypes.length > 0) {
      if (!item.subcategories || !selectedTypes.some(sId => item.subcategories.includes(sId))) {
        return false;
      }
    }
    // 3) Governorate => if selectedGovernorate != 0, item.location.id must match
    if (selectedGovernorate !== 0) {
      if (!item.location || item.location.id !== selectedGovernorate) {
        return false;
      }
    }
    // 4) Province => if selectedProvince != 0, item.sublocations must include it
    if (selectedProvince !== 0) {
      if (!item.sublocations || !item.sublocations.includes(selectedProvince)) {
        return false;
      }
    }

    return true;
  });

  return (
    <div className="activities-container">
      {/* Banner */}
      <div className="activities-banner"></div>

      <div className="activities-content">
        {/* Side Filter */}
        <div className="activities-filters">
          <h2>Filter Activities</h2>

          {/* Theme => categories => show 3 by default */}
          <div className="filter-group">
            <InputLabel>Theme</InputLabel>
            <FormGroup>
              {themes.slice(0, showAllThemes ? themes.length : 3).map(theme => (
                <FormControlLabel
                  key={theme.id}
                  control={
                    <Checkbox
                      checked={selectedThemes.includes(theme.id)}
                      onChange={handleThemeChange}
                      value={theme.id}
                    />
                  }
                  label={theme.name}
                />
              ))}
            </FormGroup>
            {themes.length > 3 && (
              <Button onClick={() => setShowAllThemes(!showAllThemes)}>
                {showAllThemes ? 'Show Less' : 'Load More'}
              </Button>
            )}
          </div>

          {/* Type => subcategories => show 3 by default */}
          <div className="filter-group">
            <InputLabel>Type</InputLabel>
            <FormGroup>
              {types.slice(0, showAllTypes ? types.length : 3).map(st => (
                <FormControlLabel
                  key={st.id}
                  control={
                    <Checkbox
                      checked={selectedTypes.includes(st.id)}
                      onChange={() => handleTypeChange(st.id)}
                    />
                  }
                  label={st.name}
                />
              ))}
            </FormGroup>
            {types.length > 3 && (
              <Button onClick={() => setShowAllTypes(!showAllTypes)}>
                {showAllTypes ? 'Show Less' : 'Load More'}
              </Button>
            )}
          </div>

          {/* Governorate => location */}
          <div className="filter-group">
            <InputLabel>Governorate</InputLabel>
            <FormControl fullWidth>
              <Select value={selectedGovernorate} onChange={handleGovernorateChange}>
                <MenuItem value={0}>All Governorates</MenuItem>
                {governorates.map(loc => (
                  <MenuItem key={loc.id} value={loc.id}>
                    {loc.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {/* Province => subLocation */}
          <div className="filter-group">
            <InputLabel>Province</InputLabel>
            <FormControl fullWidth>
              <Select value={selectedProvince} onChange={handleProvinceChange}>
                <MenuItem value={0}>All Provinces</MenuItem>
                {provinces.map(sub => (
                  <MenuItem key={sub.id} value={sub.id}>
                    {sub.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        {/* Right side => SEO + cards */}
        <div className="activities-main">
          <div className="seo-text">
            <h3>Lebanon Activities - Explore the Best</h3>
            <p>
              Discover a wide range of exciting and relaxing activities throughout Lebanon. 
              From cultural tours to adrenaline-filled excursions, our curated selection 
              ensures every traveler finds something unforgettable.
            </p>
          </div>

          <div className="activities-cards scrollable-row">
            {filteredActivities.map(item => (
              <Card
                key={item.id}
                item={item}
                onClick={() => handleCardClick(item.id)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllActivities;
