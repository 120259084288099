// src/components/ContactUs.js
import React from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './ContactUs.css';

const ContactUs = () => {
  const handleWhatsAppClick = () => {
    window.open('https://wa.me/96171941100', '_blank');
  };

  return (
    <div className="contactus-page">
      {/* Banner */}
      <div className="contactus-banner">
      </div>

      <div className="contactus-container">
        {/* Contact Methods (Phone, Email) */}
        <div className="contactus-methods">
          <div className="contactus-method-card">
            <PhoneIcon className="contactus-method-icon" />
            <h2>Phone</h2>
            <p>+961 71 941 100</p>
          </div>
          <div className="contactus-method-card">
            <EmailIcon className="contactus-method-icon" />
            <h2>Email</h2>
            <p>
              <a href="mailto:info@lebadvisor.com">info@lebadvisor.com</a>
            </p>
          </div>
        </div>

        {/* Embedded JotForm */}
        <div className="contactus-form-container">
          <iframe
            className="contactus-iframe"
            title="Contact LebAdvisor"
            onLoad={() => window.parent.scrollTo(0, 0)}
            allowTransparency="true"
            allow="geolocation; microphone; camera; fullscreen"
            src="https://form.jotform.com/242277018965464"
            frameBorder="0"
            scrolling="yes"
          ></iframe>
        </div>

        {/* "3indak trip?" Section */}
        <div className="trip-section">
          <p className="trip-text">
            3indak trip? <span>Become a LebAdvisor and add your own trip</span>
          </p>
          <FontAwesomeIcon
            icon={faWhatsapp}
            className="trip-whatsapp-icon"
            onClick={handleWhatsAppClick}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
