import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';  // optional MUI

const NotFound = () => {
  return (
    <Box
      sx={{
        minHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: 4,
      }}
    >
      {/* Title */}
      <Typography variant="h2" component="h1" gutterBottom>
        404
      </Typography>

      {/* Subtitle */}
      <Typography variant="h5" gutterBottom>
        Oops! The page you're looking for doesn't exist.
      </Typography>

      {/* Short Description */}
      <Typography variant="body1" sx={{ marginBottom: 3 }}>
        It seems you've wandered off into uncharted territory. 
        Let's get you back on track.
      </Typography>

      {/* Button to go Home or other page */}
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/"
      >
        Back to Home
      </Button>
    </Box>
  );
};

export default NotFound;
