import React, { useEffect, useState } from "react";
import {
  Snackbar,
  Alert,
  Button,
  TextField,
  MenuItem,
  Rating
} from "@mui/material";
import { useParams } from "react-router-dom";
import api, { MainUrl } from "../services/api";
import CustomDatePicker from "./booking/CustomDatePicker";
import PeriodsDropdown from "./booking/PeriodsDropdown";
import ImageCatalog from "./ImageCatalog";
import {
  FaHeart,
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaClock,
  FaUsers,
  FaDollarSign,
  FaExclamationCircle,
  FaArrowUp,
  FaArrowDown,
  FaCheckCircle,
  FaTimesCircle
} from "react-icons/fa";
import "./Details.css";

const ACTIVITY_CONTENT_TYPE_ID = 11;

const ActivityDetails = () => {
  const { id } = useParams();
  const loggedInUser = localStorage.getItem("username");

  const [activity, setActivity] = useState(null);
  const [offers, setOffers] = useState([]);
  const [imageLinks, setImageLinks] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const [loginPrompt, setLoginPrompt] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [userRating, setUserRating] = useState(0);
  const [userReviewText, setUserReviewText] = useState("");

  // Collapsible sections
  const [faqOpen, setFaqOpen] = useState(true);
  const [itineraryOpen, setItineraryOpen] = useState(true);

  useEffect(() => {
    const fetchActivity = async () => {
      try {
        const resp = await api.get(`/api/activity/${id}/`);
        setActivity(resp.data);
        setImageLinks(resp.data.catalogs.map((c) => `${MainUrl}${c.image}`));

        if (loggedInUser) {
          const favResp = await api.get(`/api/favorite-activity/${id}/`);
          setIsFavorite(favResp.data.is_favorite);
        }
        const offersResp = await api.get(`/api/activity/${id}/offers/`);
        setOffers(offersResp.data);
      } catch (error) {
        console.error("Activity fetch error", error);
      }
    };
    fetchActivity();
  }, [id, loggedInUser]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const res = await api.get(
          `/api/reviews/?content_type=${ACTIVITY_CONTENT_TYPE_ID}&object_id=${id}`
        );
        const data = res.data.results || res.data;
        setReviews(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error("Review fetch error", error);
      }
    };
    fetchReviews();
  }, [id]);

  useEffect(() => {
    if (selectedPeriod) {
      const price = parseFloat(selectedPeriod.activity_offer.price);
      setTotalPrice(price * quantity);
    }
  }, [quantity, selectedPeriod]);

  const handleBookAndPay = async () => {
    if (!loggedInUser) {
      setLoginPrompt(true);
      return;
    }
    if (!selectedPeriod) {
      alert("Select a period first.");
      return;
    }
    try {
      const bookingDetails = {
        booking_type: "activity",
        booking_id: selectedPeriod.id,
        quantity
      };
      const response = await api.post("/api/initiate-payment/", bookingDetails);
      const { session } = response.data;

      if (window.Checkout) {
        window.Checkout.configure({ session: { id: session.id } });
        window.Checkout.showPaymentPage();
      } else {
        alert("Payment unavailable.");
      }
    } catch (error) {
      console.error("Payment failed:", error);
      alert("Payment initiation failed.");
    }
  };

  const toggleFavorite = async () => {
    if (!loggedInUser) {
      setLoginPrompt(true);
      return;
    }
    try {
      if (isFavorite) await api.delete(`/api/favorite-activity/${id}/`);
      else await api.post(`/api/favorite-activity/${id}/`);
      setIsFavorite(!isFavorite);
    } catch (error) {
      console.error("Favorite toggle error", error);
    }
  };

  const handleOfferChange = (event) => {
    const offerId = parseInt(event.target.value, 10);
    const offer = offers.find((o) => o.id === offerId);
    setSelectedOffer(offer);
    setSelectedPeriod(null);
    setQuantity(1);
    setTotalPrice(0);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedOffer(null);
    setSelectedPeriod(null);
    setQuantity(1);
    setTotalPrice(0);
  };

  const handleReviewSubmit = async () => {
    if (!loggedInUser) {
      setLoginPrompt(true);
      return;
    }
    try {
      const data = {
        content_type: ACTIVITY_CONTENT_TYPE_ID,
        object_id: parseInt(id, 10),
        rating: userRating,
        text: userReviewText
      };
      await api.post("/api/reviews/", data);
      setUserRating(0);
      setUserReviewText("");
      const response = await api.get(
        `/api/reviews/?content_type=${ACTIVITY_CONTENT_TYPE_ID}&object_id=${id}`
      );
      const reviewsData = response.data.results || response.data;
      setReviews(Array.isArray(reviewsData) ? reviewsData : []);
    } catch (error) {
      console.error("Review error:", error);
      alert("Review submit failed.");
    }
  };

  if (!activity) return <div className="loading">Loading...</div>;

  return (
    <div className="page-container">
      <Snackbar
        open={loginPrompt}
        autoHideDuration={3000}
        onClose={() => setLoginPrompt(false)}
      >
        <Alert onClose={() => setLoginPrompt(false)} severity="warning">
          Please log in to continue.
        </Alert>
      </Snackbar>

      <div className="header-section">
        <h1 className="main-title">{activity.title}</h1>
        <FaHeart
          className={`fav-icon ${isFavorite ? "active" : ""}`}
          onClick={toggleFavorite}
        />
      </div>

      <div className="top-section">
        <div className="left-column">
          <img
            src={`${MainUrl}/${activity.image}`}
            alt={activity.title}
            className="main-image"
          />
          <ImageCatalog imageLinks={imageLinks} />
        </div>
        <div className="right-column">
          <div className="booking-panel">
            <h2>Book Activity</h2>
            <CustomDatePicker
              from={new Date().toISOString().split("T")[0]}
              to={activity.available_to}
              daysOff={activity.days_off || ""}
              onDateChange={handleDateChange}
            />
            {selectedDate && (
              <>
                <TextField
                  select
                  label="Select Offer"
                  value={selectedOffer?.id || ""}
                  onChange={handleOfferChange}
                  fullWidth
                  margin="normal"
                >
                  <MenuItem value="">Select Offer</MenuItem>
                  {offers.map((offer) => (
                    <MenuItem key={offer.id} value={offer.id}>
                      {offer.title} - ${offer.price}
                    </MenuItem>
                  ))}
                </TextField>
                {selectedOffer && (
                  <PeriodsDropdown
                    selectedDate={selectedDate}
                    offerId={selectedOffer.id}
                    selectedPeriod={selectedPeriod}
                    setSelectedPeriod={setSelectedPeriod}
                  />
                )}
              </>
            )}
            {selectedPeriod && (
              <>
                <TextField
                  label="Quantity"
                  type="number"
                  InputProps={{
                    inputProps: { min: 1, max: selectedPeriod.stock }
                  }}
                  value={quantity}
                  onChange={(e) => {
                    const val = Math.max(
                      1,
                      Math.min(selectedPeriod.stock, Number(e.target.value))
                    );
                    setQuantity(val);
                  }}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
                <p className="price-label">
                  <strong>Total Price:</strong> ${totalPrice.toFixed(2)}
                </p>
                <Button
                  onClick={handleBookAndPay}
                  variant="contained"
                  sx={{
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#1565c0" }
                  }}
                >
                  Book & Pay
                </Button>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="info-section">
        <div className="detail-box">
          <p className="description">{activity.description}</p>
          <p>
            <FaDollarSign /> <strong>Price:</strong> {activity.price}
          </p>
          <p>
            <FaCalendarAlt /> <strong>Available from:</strong>{" "}
            {activity.available_from} &nbsp;
            <FaCalendarAlt /> <strong>to:</strong> {activity.available_to}
          </p>
          <p>
            <FaMapMarkerAlt /> <strong>Location:</strong>{" "}
            {activity.location.name}
          </p>
          <p>
            <FaClock /> <strong>Start time:</strong> {activity.start_time}
          </p>
          <p>
            <FaClock /> <strong>End time:</strong> {activity.end_time}
          </p>
          <p>
            <FaUsers /> <strong>Group size:</strong> {activity.group_size}
          </p>
          <p>
            <FaUsers /> <strong>Age range:</strong>{" "}
            {activity.participant_age_range}
          </p>
          <p>
            <FaExclamationCircle /> <strong>Cancellation:</strong>{" "}
            {activity.cancellation_policy}
          </p>

          <div className="included-excluded-area">
            <div>
              <h4>Included</h4>
              <ul>
                {activity.included_items.map((item) => (
                  <li key={item.id}>
                    <FaCheckCircle className="check-icon" /> {item.include}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h4>Not Included</h4>
              <ul>
                {activity.excluded_items.map((item) => (
                  <li key={item.id}>
                    <FaTimesCircle className="times-icon" /> {item.Exclude}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {activity.map && (
            <div className="location-box">
              <FaMapMarkerAlt /> <strong>Activity location:</strong>
              <div
                dangerouslySetInnerHTML={{ __html: activity.map }}
                className="map-area"
              />
            </div>
          )}
        </div>

        {activity.itinerary && activity.itinerary.length > 0 && (
          <div className="collapsible-section">
            <div
              className="collapsible-header"
              onClick={() => setItineraryOpen(!itineraryOpen)}
            >
              <h3>Itinerary</h3>
              {itineraryOpen ? <FaArrowUp /> : <FaArrowDown />}
            </div>
            {itineraryOpen && (
              <div className="collapsible-content">
                {activity.itinerary.map((step) => (
                  <div key={step.id} className="itinerary-step">
                    <h4>{step.title}</h4>
                    <p>{step.activity}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        <div className="collapsible-section">
          <div className="collapsible-header" onClick={() => setFaqOpen(!faqOpen)}>
            <h3>FAQs</h3>
            {faqOpen ? <FaArrowUp /> : <FaArrowDown />}
          </div>
          {faqOpen && (
            <div className="collapsible-content">
              {activity.faqs.map((faq) => (
                <div key={faq.id} className="faq-item">
                  <p>
                    <strong>Q:</strong> {faq.question}
                  </p>
                  <p>
                    <strong>A:</strong> {faq.answer}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="reviews-section">
        <h3>Reviews</h3>
        {reviews.length > 0 ? (
          reviews.map((rev) => (
            <div key={rev.id} className="single-review">
              <div className="review-header">
                <strong>{rev.username}</strong>
                <Rating value={rev.rating} readOnly size="small" />
              </div>
              <p>{rev.text}</p>
              <hr />
            </div>
          ))
        ) : (
          <p>No reviews yet</p>
        )}

        <h4>Leave a Review</h4>
        <div className="review-form">
          <Rating
            name="activity-rating"
            value={userRating}
            onChange={(_, newValue) => setUserRating(newValue)}
            size="medium"
          />
          <TextField
            label="Share your experience"
            multiline
            rows={4}
            value={userReviewText}
            onChange={(e) => setUserReviewText(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <Button variant="contained" onClick={handleReviewSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ActivityDetails;
