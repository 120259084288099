import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  MenuItem,
  Snackbar,
  Alert,
  Rating
} from "@mui/material";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import api, { MainUrl } from "../services/api";
import {
  FaHeart,
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaClock,
  FaUsers,
  FaDollarSign,
  FaExclamationCircle,
  FaVolumeUp,
  FaCheckCircle,
  FaTimesCircle,
  FaListUl,
  FaArrowUp,
  FaArrowDown
} from "react-icons/fa";
import ImageCatalog from "./ImageCatalog";
import TourDatePicker from "./booking/TourDatePicker";
import "./Details.css";

const TOUR_CONTENT_TYPE_ID = 24;

const TourDetails = () => {
  const { id } = useParams();
  const loggedInUser = localStorage.getItem("username");

  const [tour, setTour] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [selectedTourDay, setSelectedTourDay] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const [imageLinks, setImageLinks] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [availableDates, setAvailableDates] = useState([]);
  const [loginPrompt, setLoginPrompt] = useState(false);
  const [isCheckoutLoaded, setIsCheckoutLoaded] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [userRating, setUserRating] = useState(0);
  const [userReviewText, setUserReviewText] = useState("");

  // Collapsible sections (shown by default)
  const [itineraryOpen, setItineraryOpen] = useState(true);
  const [faqOpen, setFaqOpen] = useState(true);

  useEffect(() => {
    const fetchTour = async () => {
      try {
        const response = await api.get(`/api/tour/${id}/`);
        setTour(response.data);
        setImageLinks(response.data.catalogs.map((c) => `${MainUrl}${c.image}`));

        if (loggedInUser) {
          const favResp = await api.get(`/api/favorite-tour/${id}/`);
          setIsFavorite(favResp.data.is_favorite);
        }
      } catch (error) {
        console.error("Failed to fetch tour details", error);
      }
    };
    fetchTour();
  }, [id, loggedInUser]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const res = await api.get(
          `/api/reviews/?content_type=${TOUR_CONTENT_TYPE_ID}&object_id=${id}`
        );
        const data = res.data.results || res.data;
        setReviews(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error("Failed to fetch reviews", error);
      }
    };
    fetchReviews();
  }, [id]);

  useEffect(() => {
    if (selectedOffer) {
      const fetchTourDays = async () => {
        try {
          const response = await api.get(`/api/tourdays/${selectedOffer.id}/`);
          const available = response.data.map((day) => ({
            date: format(new Date(day.day), "yyyy-MM-dd"),
            ...day
          }));
          setAvailableDates(available);
        } catch (error) {
          console.error("Failed to fetch tour days", error);
        }
      };
      fetchTourDays();
    }
  }, [selectedOffer]);

  useEffect(() => {
    if (selectedTourDay) {
      const price = parseFloat(selectedTourDay.tour_offer.price);
      setTotalPrice(price * quantity);
    }
  }, [quantity, selectedTourDay]);

  useEffect(() => {
    if (!window.Checkout) {
      const script = document.createElement("script");
      script.src =
        "https://test-gateway.mastercard.com/static/checkout/checkout.min.js";
      script.async = true;
      script.onload = () => setIsCheckoutLoaded(true);
      script.onerror = () => setIsCheckoutLoaded(false);
      document.head.appendChild(script);
      return () => document.head.removeChild(script);
    } else {
      setIsCheckoutLoaded(true);
    }
  }, []);

  const handleBookAndPay = async () => {
    if (!loggedInUser) {
      setLoginPrompt(true);
      return;
    }
    if (!selectedTourDay) {
      alert("Select a date first.");
      return;
    }
    try {
      const bookingDetails = {
        booking_type: "tour",
        booking_id: selectedOffer.id,
        quantity
      };
      const response = await api.post("/api/initiate-payment/", bookingDetails);
      const { session } = response.data;

      if (window.Checkout) {
        window.Checkout.configure({ session: { id: session.id } });
        window.Checkout.showPaymentPage();
      } else {
        alert("Payment unavailable.");
      }
    } catch (error) {
      console.error("Payment initiation failed:", error);
      alert("Payment failed, try again.");
    }
  };

  const toggleFavorite = async () => {
    if (!loggedInUser) {
      setLoginPrompt(true);
      return;
    }
    try {
      if (isFavorite) await api.delete(`/api/favorite-tour/${id}/`);
      else await api.post(`/api/favorite-tour/${id}/`);
      setIsFavorite(!isFavorite);
    } catch (error) {
      console.error("Favorite toggle error", error);
    }
  };

  const handleOfferChange = (e) => {
    const offerId = parseInt(e.target.value, 10);
    const offer = tour.offers.find((o) => o.id === offerId);
    setSelectedOffer(offer);
    setSelectedTourDay(null);
    setAvailableDates([]);
    setQuantity(1);
    setTotalPrice(0);
  };

  const handleDateChange = (date) => {
    if (!loggedInUser) {
      setLoginPrompt(true);
      return;
    }
    const selectedDay = availableDates.find((d) => d.date === date);
    setSelectedTourDay(selectedDay);
    setQuantity(1);
    setTotalPrice(0);
  };

  const handleReviewSubmit = async () => {
    if (!loggedInUser) {
      setLoginPrompt(true);
      return;
    }
    try {
      const data = {
        content_type: TOUR_CONTENT_TYPE_ID,
        object_id: parseInt(id, 10),
        rating: userRating,
        text: userReviewText
      };
      await api.post("/api/reviews/", data);
      setUserRating(0);
      setUserReviewText("");
      const res = await api.get(
        `/api/reviews/?content_type=${TOUR_CONTENT_TYPE_ID}&object_id=${id}`
      );
      const newData = res.data.results || res.data;
      setReviews(Array.isArray(newData) ? newData : []);
    } catch (error) {
      console.error("Review error:", error);
      alert("Submit review failed.");
    }
  };

  if (!tour) return <div className="loading">Loading...</div>;

  return (
    <div className="page-container">
      <Snackbar
        open={loginPrompt}
        autoHideDuration={3000}
        onClose={() => setLoginPrompt(false)}
      >
        <Alert onClose={() => setLoginPrompt(false)} severity="warning">
          Please log in to continue.
        </Alert>
      </Snackbar>

      <div className="header-section">
        <h1 className="main-title">{tour.title}</h1>
        <FaHeart
          className={`fav-icon ${isFavorite ? "active" : ""}`}
          onClick={toggleFavorite}
        />
      </div>

      <div className="top-section">
        <div className="left-column">
          <img
            src={`${MainUrl}/${tour.image}`}
            alt={tour.title}
            className="main-image"
          />
          <ImageCatalog imageLinks={imageLinks} />
        </div>
        <div className="right-column">
          <div className="booking-panel">
            <h2>Book This Tour</h2>
            <TextField
              select
              label="Select Offer"
              value={selectedOffer?.id || ""}
              onChange={handleOfferChange}
              fullWidth
              margin="normal"
            >
              <MenuItem value="">Select Offer</MenuItem>
              {tour.offers.map((offer) => (
                <MenuItem key={offer.id} value={offer.id}>
                  {offer.title} - ${offer.price}
                </MenuItem>
              ))}
            </TextField>

            {selectedOffer && (
              <TourDatePicker
                availableTourDays={availableDates.map((d) => d.date)}
                onDateChange={handleDateChange}
              />
            )}

            {selectedTourDay && (
              <>
                <TextField
                  type="number"
                  label="Quantity"
                  value={quantity}
                  onChange={(e) =>
                    setQuantity(
                      Math.min(
                        Math.max(parseInt(e.target.value, 10), 1),
                        selectedTourDay.stock
                      )
                    )
                  }
                  InputProps={{
                    inputProps: { min: 1, max: selectedTourDay.stock }
                  }}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
                <p className="price-label">
                  <strong>Total Price:</strong> ${totalPrice.toFixed(2)}
                </p>
                <Button
                  onClick={handleBookAndPay}
                  variant="contained"
                  sx={{
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#1565c0" }
                  }}
                  disabled={!isCheckoutLoaded}
                >
                  Book & Pay
                </Button>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="info-section">
        <div className="detail-box">
          <p className="description">{tour.description}</p>
          <p>
            <FaDollarSign /> <strong>Price:</strong> {tour.price}
          </p>
          <p>
            <FaCalendarAlt /> <strong>Available from:</strong> {tour.available_from} &nbsp;
            <FaCalendarAlt /> <strong>to:</strong> {tour.available_to}
          </p>
          <p>
            <FaClock /> <strong>Duration:</strong> {tour.period} hours
          </p>
          <p>
            <FaVolumeUp /> <strong>Languages:</strong> {tour.languages}
          </p>
          <p>
            <FaUsers /> <strong>Minimum age:</strong> {tour.min_age}
          </p>
          <p>
            <FaExclamationCircle /> <strong>Cancellation:</strong>{" "}
            {tour.cancellation_policy}
          </p>

          <div className="included-excluded-area">
            <div>
              <h4>Included</h4>
              <ul>
                {tour.included_items.map((item) => (
                  <li key={item.id}>
                    <FaCheckCircle className="check-icon" /> {item.include}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h4>Not Included</h4>
              <ul>
                {tour.excluded_items.map((item) => (
                  <li key={item.id}>
                    <FaTimesCircle className="times-icon" /> {item.Exclude}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="location-box">
            <FaMapMarkerAlt /> <strong>Pickup location:</strong>
            <div
              dangerouslySetInnerHTML={{ __html: tour.pickup_location }}
              className="map-area"
            />
          </div>
        </div>

        <div className="collapsible-section">
          <div className="collapsible-header" onClick={() => setItineraryOpen(!itineraryOpen)}>
            <h3>Itinerary <FaListUl /></h3>
            {itineraryOpen ? <FaArrowUp /> : <FaArrowDown />}
          </div>
          {itineraryOpen && (
            <div className="collapsible-content">
              {tour.itinerary.map((step) => (
                <div key={step.id} className="itinerary-step">
                  <h4>{step.title}</h4>
                  <p>{step.activity}</p>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="collapsible-section">
          <div className="collapsible-header" onClick={() => setFaqOpen(!faqOpen)}>
            <h3>FAQs</h3>
            {faqOpen ? <FaArrowUp /> : <FaArrowDown />}
          </div>
          {faqOpen && (
            <div className="collapsible-content">
              {tour.faqs.map((faq) => (
                <div key={faq.id} className="faq-item">
                  <p>
                    <strong>Q:</strong> {faq.question}
                  </p>
                  <p>
                    <strong>A:</strong> {faq.answer}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="reviews-section">
        <h3>Reviews</h3>
        {reviews.length > 0 ? (
          reviews.map((rev) => (
            <div key={rev.id} className="single-review">
              <div className="review-header">
                <strong>{rev.username}</strong>
                <Rating value={rev.rating} readOnly size="small" />
              </div>
              <p>{rev.text}</p>
              <hr />
            </div>
          ))
        ) : (
          <p>No reviews yet</p>
        )}

        <h4>Leave a Review</h4>
        <div className="review-form">
          <Rating
            name="tour-rating"
            value={userRating}
            onChange={(_, newValue) => setUserRating(newValue)}
            size="medium"
          />
          <TextField
            label="Share your experience"
            multiline
            rows={4}
            value={userReviewText}
            onChange={(e) => setUserReviewText(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <Button variant="contained" onClick={handleReviewSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TourDetails;
