import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import api from "../services/api";
import Card from "./Card"; // your existing Card component
import "./MainPage.css";
import { FaRoute, FaSuitcaseRolling, FaBiking } from "react-icons/fa";

const MainPage = () => {
  // State for homepage data
  const [featured, setFeatured] = useState([]);
  const [familyPicks, setFamilyPicks] = useState([]);
  const [seasonalHighlights, setSeasonalHighlights] = useState([]);
  const [localFavorites, setLocalFavorites] = useState([]);

  // Search form states
  const [keyword, setKeyword] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [themes, setThemes] = useState([]);
  const [selectedThemeId, setSelectedThemeId] = useState("");

  // Category + location options
  const categoryOptions = [
    { value: "", label: "All Categories" },
    { value: "activities", label: "Activities" },
    { value: "tours", label: "Tours" },
    { value: "packages", label: "Packages" },
  ];
  const locationOptions = [
    { value: "", label: "All Locations" },
    { value: "Beirut", label: "Beirut" },
    { value: "Mount Lebanon", label: "Mount Lebanon" },
    { value: "North Lebanon", label: "North Lebanon" },
    { value: "Akkar", label: "Akkar" },
    { value: "Baalbek-Hermel", label: "Baalbek-Hermel" },
    { value: "Bekaa", label: "Bekaa" },
    { value: "South Lebanon", label: "South Lebanon" },
    { value: "Nabatieh", label: "Nabatieh" },
    { value: "Keserwan", label: "Keserwan" },
  ];

  const navigate = useNavigate();

  // Refs for scrollable rows
  const featuredRef = useRef(null);
  const familyRef = useRef(null);
  const seasonalRef = useRef(null);
  const localRef = useRef(null);

  // ============ DATA FETCHING ============
  // Home data: featured, family picks, etc.
  useEffect(() => {
    const fetchHomeData = async () => {
      try {
        const [featuredRes, familyRes, seasonalRes, localRes] = await Promise.all([
          api.get("/api/featured-items/"),
          api.get("/api/family-picks/"),
          api.get("/api/seasonal-highlights/"),
          api.get("/api/local-favorites/"),
        ]);
        setFeatured(featuredRes.data);
        setFamilyPicks(familyRes.data);
        setSeasonalHighlights(seasonalRes.data);
        setLocalFavorites(localRes.data);
      } catch (error) {
        console.error("Failed to fetch homepage data:", error);
      }
    };
    fetchHomeData();
  }, []);

  // Themes categories from /api/categories
  useEffect(() => {
    api
      .get("/api/categories/")
      .then((res) => setThemes(res.data))
      .catch((err) => console.error("Failed to fetch categories:", err));
  }, []);

  // ============ AUTO SCROLL FOR FEATURED SECTION ============
  // We auto-scroll the "Featured" row, faster: ~20ms
  useEffect(() => {
    const scrollContainer = featuredRef.current;
    if (!scrollContainer) return;

    // Scroll 1 pixel every 20ms
    const autoScrollInterval = setInterval(() => {
      scrollContainer.scrollLeft += 1;
      // If desired, you could check if we are near the end and then reset
      // or bounce back. For now, it just continuously scrolls to the right.
    }, 20);

    return () => clearInterval(autoScrollInterval);
  }, []);

  // ============ CLICK HANDLERS ============
  const handleSearch = () => {
    navigate(
      `/search-results?keyword=${encodeURIComponent(keyword)}`
      + `&category=${encodeURIComponent(selectedCategory)}`
      + `&location=${encodeURIComponent(selectedLocation)}`
      + `&theme_id=${encodeURIComponent(selectedThemeId)}`
    );
  };

  const handleCardClick = (id, type) => {
    navigate(`/${type}-details/${id}`);
  };

  // ============ ARROW SCROLL LOGIC ============
  const scrollLeft = (ref) => {
    if (ref.current) {
      ref.current.scrollLeft -= 200;
    }
  };
  const scrollRight = (ref) => {
    if (ref.current) {
      ref.current.scrollLeft += 200;
    }
  };

  return (
    <div className="main-page">
      {/* Banner */}
      <div className="banner"></div>

      {/* ========== SEARCH SECTION ========== */}
      <div className="search-section fade-up">
        <h2 className="search-heading">Find Your Next Adventure</h2>
        <div className="search-seo-text">
          <p>
            Explore our top-rated tours, packages, and outdoor activities in Lebanon.
            Book your dream adventure, immerse in cultural gems, or discover scenic
            escapes with our curated offers.
          </p>
        </div>
        <div className="search-fields">
          <input
            type="text"
            placeholder="Search by keyword..."
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            className="search-input"
          />
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="search-select"
          >
            {categoryOptions.map((cat) => (
              <option key={cat.value} value={cat.value}>
                {cat.label}
              </option>
            ))}
          </select>
          <select
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
            className="search-select"
          >
            {locationOptions.map((loc) => (
              <option key={loc.value} value={loc.value}>
                {loc.label}
              </option>
            ))}
          </select>
          <select
            value={selectedThemeId}
            onChange={(e) => setSelectedThemeId(e.target.value)}
            className="search-select"
          >
            <option value="">All Themes</option>
            {themes.map((themeObj) => (
              <option key={themeObj.id} value={themeObj.id}>
                {themeObj.name}
              </option>
            ))}
          </select>
          <button className="search-button" onClick={handleSearch}>
            Search
          </button>
        </div>
      </div>

      {/* ========== TOP CARDS SECTION ========== */}
      <section className="top-cards-section fade-up">
        <div className="section-title">Explore</div>
        <div className="top-cards-container">
          <div className="pro-card" onClick={() => navigate("/all-tours/")}>
            <FaRoute className="pro-card-icon" />
            <h3 className="pro-card-title">Tours</h3>
            <p className="pro-card-desc">
              Discover guided excursions that showcase Lebanon's hidden gems and iconic sites.
            </p>
          </div>
          <div className="pro-card" onClick={() => navigate("/all-packages/")}>
            <FaSuitcaseRolling className="pro-card-icon" />
            <h3 className="pro-card-title">Packages</h3>
            <p className="pro-card-desc">
              Enjoy hassle-free travel bundles, including lodging and curated activities.
            </p>
          </div>
          <div className="pro-card" onClick={() => navigate("/all-activities/")}>
            <FaBiking className="pro-card-icon" />
            <h3 className="pro-card-title">Activities</h3>
            <p className="pro-card-desc">
              Get your adrenaline fix or relax with outdoor pursuits, tailored for every taste.
            </p>
          </div>
        </div>
      </section>

      {/* ========== Featured Section (Auto-scrolling) ========== */}
      <section className="section fade-up">
        <h2 className="section-title">Featured</h2>
        <div className="section-description">
          <p>
            Immerse yourself in top-rated experiences across Lebanon. Our Featured section
            highlights the best tours, packages, and activities for unforgettable adventures.
          </p>
        </div>

        {/* Arrows + Scrollable row */}
        <div className="scrollable-row-container">
          <div
            className="arrow left-arrow"
            onClick={() => scrollLeft(featuredRef)}
          >
            &#x276E;
          </div>

          <div className="scrollable-row" ref={featuredRef}>
            {featured.activities?.map((activity) => (
              <Card
                key={activity.id}
                item={activity}
                onClick={() => handleCardClick(activity.id, "activity")}
              />
            ))}
            {featured.packages?.map((pkg) => (
              <Card
                key={pkg.id}
                item={pkg}
                onClick={() => handleCardClick(pkg.id, "package")}
              />
            ))}
            {featured.tours?.map((tour) => (
              <Card
                key={tour.id}
                item={tour}
                onClick={() => handleCardClick(tour.id, "tour")}
              />
            ))}
          </div>

          <div
            className="arrow right-arrow"
            onClick={() => scrollRight(featuredRef)}
          >
            &#x276F;
          </div>
        </div>
      </section>

      {/* ========== Family Picks Section ========== */}
      <section className="section section-family-picks fade-up">
        <h2 className="section-title">Family Picks</h2>
        <div className="section-description">
          <p>
            Traveling with family? Our Family Picks offer safe, entertaining,
            and educational experiences for all ages.
          </p>
        </div>
        <div className="section-bg"></div>
        <div className="scrollable-row-container">
          <div
            className="arrow left-arrow"
            onClick={() => scrollLeft(familyRef)}
          >
            &#x276E;
          </div>
          <div className="scrollable-row" ref={familyRef}>
            {familyPicks.activities?.map((activity) => (
              <Card
                key={activity.id}
                item={activity}
                onClick={() => handleCardClick(activity.id, "activity")}
              />
            ))}
            {familyPicks.packages?.map((pkg) => (
              <Card
                key={pkg.id}
                item={pkg}
                onClick={() => handleCardClick(pkg.id, "package")}
              />
            ))}
            {familyPicks.tours?.map((tour) => (
              <Card
                key={tour.id}
                item={tour}
                onClick={() => handleCardClick(tour.id, "tour")}
              />
            ))}
          </div>
          <div
            className="arrow right-arrow"
            onClick={() => scrollRight(familyRef)}
          >
            &#x276F;
          </div>
        </div>
      </section>

      {/* ========== Seasonal Highlights Section ========== */}
      <section className="section fade-up">
        <h2 className="section-title">Seasonal Highlights</h2>
        <div className="section-description">
          <p>
            Embrace each season in Lebanon—from sunny beach escapes to cozy winter
            getaways in the mountains.
          </p>
        </div>
        <div className="scrollable-row-container">
          <div className="arrow left-arrow" onClick={() => scrollLeft(seasonalRef)}>
            &#x276E;
          </div>
          <div className="scrollable-row" ref={seasonalRef}>
            {seasonalHighlights.activities?.map((activity) => (
              <Card
                key={activity.id}
                item={activity}
                onClick={() => handleCardClick(activity.id, "activity")}
              />
            ))}
            {seasonalHighlights.packages?.map((pkg) => (
              <Card
                key={pkg.id}
                item={pkg}
                onClick={() => handleCardClick(pkg.id, "package")}
              />
            ))}
            {seasonalHighlights.tours?.map((tour) => (
              <Card
                key={tour.id}
                item={tour}
                onClick={() => handleCardClick(tour.id, "tour")}
              />
            ))}
          </div>
          <div
            className="arrow right-arrow"
            onClick={() => scrollRight(seasonalRef)}
          >
            &#x276F;
          </div>
        </div>
      </section>

      {/* ========== Local Favorites Section ========== */}
      <section className="section fade-up">
        <h2 className="section-title">Local Favorites</h2>
        <div className="section-description">
          <p>
            Experience Lebanon like a true local. From hidden-gem eateries to 
            off-the-beaten-path tours, our Local Favorites connect you with the 
            heart of this enchanting country.
          </p>
        </div>
        <div className="scrollable-row-container">
          <div className="arrow left-arrow" onClick={() => scrollLeft(localRef)}>
            &#x276E;
          </div>
          <div className="scrollable-row" ref={localRef}>
            {localFavorites.activities?.map((activity) => (
              <Card
                key={activity.id}
                item={activity}
                onClick={() => handleCardClick(activity.id, "activity")}
              />
            ))}
            {localFavorites.packages?.map((pkg) => (
              <Card
                key={pkg.id}
                item={pkg}
                onClick={() => handleCardClick(pkg.id, "package")}
              />
            ))}
            {localFavorites.tours?.map((tour) => (
              <Card
                key={tour.id}
                item={tour}
                onClick={() => handleCardClick(tour.id, "tour")}
              />
            ))}
          </div>
          <div
            className="arrow right-arrow"
            onClick={() => scrollRight(localRef)}
          >
            &#x276F;
          </div>
        </div>
      </section>
    </div>
  );
};

export default MainPage;
