import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // for programmatic navigation
import {
  Container,
  CircularProgress,
  Alert,
  Grid,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Box
} from '@mui/material';
import {
  FaExclamationTriangle,
  FaDollarSign,
  FaShoppingCart,
  FaCalendarAlt,
  FaClock,
  FaCheck,
  FaTimes,
  FaQrcode,
  FaStar,  // for "Active Bookings" icon
  FaCheckCircle // for "Redeemed Bookings" icon
} from 'react-icons/fa';

import api, { MainUrl } from '../services/api';
import './CustomerBookingPage.css'; // The improved stacked design CSS

const CustomerBookingPage = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Bookings from your three endpoints
  const [activityBookings, setActivityBookings] = useState([]);
  const [packageBookings, setPackageBookings] = useState([]);
  const [tourBookings, setTourBookings] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        // Fetch Activity, Package, and Tour bookings in parallel
        const [actRes, pkgRes, tourRes] = await Promise.all([
          api.get('/api/customer/bookings/'),  // Activities
          api.get('/api/customer/packagesb/'), // Packages
          api.get('/api/customer/toursb/')      // Tours
        ]);

        setActivityBookings(actRes.data || []);
        setPackageBookings(pkgRes.data || []);
        setTourBookings(tourRes.data || []);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchBookings();
  }, []);

  // Helper to determine the "day" (date) for each booking
  //  - Activity: booking.period.day
  //  - Tour: booking.tourday.day
  //  - Package: booking.start_date or booking.end_date
  const getBookingDay = (booking) => {
    if (booking.period?.day) return booking.period.day;            // Activity
    if (booking.tourday?.day) return booking.tourday.day;          // Tour
    if (booking.start_date) return booking.start_date;             // Package (assume start_date as the "booking day")
    return null; // fallback
  };

  // Combine all bookings into one array
  const allBookings = [
    ...activityBookings.map(b => ({ ...b, bookingType: 'activity' })),
    ...packageBookings.map(b => ({ ...b, bookingType: 'package' })),
    ...tourBookings.map(b => ({ ...b, bookingType: 'tour' }))
  ];

  // Check if booking day is in the future or past
  const today = new Date().setHours(0, 0, 0, 0); // compare at midnight
  const isInFuture = (bookingDay) => new Date(bookingDay) >= today;

  // Split into two categories: Active vs. Redeemed
  const activeBookings = allBookings.filter((b) => {
    const day = getBookingDay(b);
    if (!day) return false;
    return isInFuture(day);
  });

  const redeemedBookings = allBookings.filter((b) => {
    const day = getBookingDay(b);
    if (!day) return false;
    return !isInFuture(day);
  });

  // Navigate to the correct details page
  const handleOfferClick = (booking) => {
    const activityId = booking.period?.activity_offer?.activity?.id;
    const packageId = booking.package_offer?.package?.id;
    const tourId = booking.tourday?.tour_offer?.tour?.id;

    if (activityId) {
      navigate(`/activity-details/${activityId}`);
    } else if (packageId) {
      navigate(`/package-details/${packageId}`);
    } else if (tourId) {
      navigate(`/tour-details/${tourId}`);
    } else {
      console.warn('Could not determine booking type for:', booking.id);
    }
  };

  // Render a single stacked booking card
  const renderBookingCard = (booking) => {
    // Extracting fields
    const title =
      booking.period?.activity_offer?.activity?.title ||
      booking.tourday?.tour_offer?.tour?.title ||
      booking.package_offer?.package?.title ||
      'Booking';

    const offerTitle =
      booking.period?.activity_offer?.title ||
      booking.tourday?.tour_offer?.title ||
      booking.package_offer?.title ||
      'Offer';

    const price = booking.price || 0;
    const quantity = booking.quantity || 1;

    const day = getBookingDay(booking);

    // Times
    let startTime, endTime;
    if (booking.period) {
      // Activity
      startTime = booking.period.time_from;
      endTime = booking.period.time_to;
    } else if (booking.tourday) {
      // Tour
      startTime = booking.tourday.tour_offer?.tour?.pickup_time;
      endTime = booking.tourday.tour_offer?.tour?.dropoff_time;
    } else if (booking.package_offer) {
      // Package
      startTime = booking.start_date;
      endTime = booking.end_date;
    }

    // Paid or not
    const paidElem = booking.paid ? (
      <span className="badge-paid">
        <FaCheck className="icon-inline" />
        Paid
      </span>
    ) : (
      <span className="badge-unpaid">
        <FaTimes className="icon-inline" />
        Unpaid
      </span>
    );

    // Confirmed or not
    const confirmedElem = booking.confirmed ? (
      <span className="badge-confirmed">
        <FaCheck className="icon-inline" />
        Confirmed
      </span>
    ) : (
      <span className="badge-not-confirmed">
        <FaTimes className="icon-inline" />
        Not Confirmed
      </span>
    );

    // Possibly show QR code
    let qrElem = <span className="no-qr">N/A</span>;
    if (booking.confirmed && booking.qr_code) {
      qrElem = (
        <div className="qr-code-stacked">
          <FaQrcode className="icon-inline" />
          <img
            src={`${MainUrl}${booking.qr_code}`}
            alt="QR Code"
            className="qr-image"
          />
        </div>
      );
    }

    return (
      <Card
        key={booking.id}
        className="stacked-booking-card"
        onClick={() => handleOfferClick(booking)}
      >
        <CardContent>
          {/* Title */}
          <Box className="stacked-row">
            <Typography variant="subtitle2" className="stacked-label">
              Title:
            </Typography>
            <Typography variant="body2" className="stacked-title clickable">
              {title}
            </Typography>
          </Box>

          {/* Offer */}
          <Box className="stacked-row">
            <Typography variant="subtitle2" className="stacked-label">
              Offer:
            </Typography>
            <Typography variant="body2">{offerTitle}</Typography>
          </Box>

          {/* Price */}
          <Box className="stacked-row">
            <Typography variant="subtitle2" className="stacked-label">
              <FaDollarSign className="icon-inline" /> Price:
            </Typography>
            <Typography variant="body2">${price}</Typography>
          </Box>

          {/* Quantity */}
          <Box className="stacked-row">
            <Typography variant="subtitle2" className="stacked-label">
              <FaShoppingCart className="icon-inline" /> Quantity:
            </Typography>
            <Typography variant="body2">{quantity}</Typography>
          </Box>

          {/* Day */}
          <Box className="stacked-row">
            <Typography variant="subtitle2" className="stacked-label">
              <FaCalendarAlt className="icon-inline" /> Day:
            </Typography>
            <Typography variant="body2">{day}</Typography>
          </Box>

          {/* Start Time */}
          <Box className="stacked-row">
            <Typography variant="subtitle2" className="stacked-label">
              <FaClock className="icon-inline" /> Starts:
            </Typography>
            <Typography variant="body2">{startTime?.toString()}</Typography>
          </Box>

          {/* End Time */}
          <Box className="stacked-row">
            <Typography variant="subtitle2" className="stacked-label">
              <FaClock className="icon-inline" /> Ends:
            </Typography>
            <Typography variant="body2">{endTime?.toString()}</Typography>
          </Box>

          {/* Paid */}
          <Box className="stacked-row">
            <Typography variant="subtitle2" className="stacked-label">
              Paid:
            </Typography>
            {paidElem}
          </Box>

          {/* Confirmed */}
          <Box className="stacked-row">
            <Typography variant="subtitle2" className="stacked-label">
              Confirmed:
            </Typography>
            {confirmedElem}
          </Box>

          {/* QR Code */}
          <Box className="stacked-row">
            <Typography variant="subtitle2" className="stacked-label">
              QR Code:
            </Typography>
            {qrElem}
          </Box>
        </CardContent>
      </Card>
    );
  };

  // Renders an array of bookings in stacked card layout
  const renderStackedBookings = (bookings) => {
    if (!bookings.length) {
      return <Typography className="no-bookings-stacked">No bookings available</Typography>;
    }
    return bookings.map((booking) => renderBookingCard(booking));
  };

  if (loading) {
    return (
      <Container className="stacked-container">
        <CircularProgress className="stacked-loading" />
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="stacked-container">
        <Alert severity="error" className="stacked-error">
          Error loading bookings: {error.message}
        </Alert>
      </Container>
    );
  }

  return (
    <Container className="stacked-container">
      <Grid container spacing={4} direction="column">
        {/* Active Bookings (Day in the future) */}
        <Grid item>
          <Card className="stacked-section-card fancy-hover">
            <CardHeader
              className="stacked-section-header"
              title={
                <span className="section-title">
                  <FaStar className="icon-inline" /> Active Bookings
                </span>
              }
            />
            <CardContent>
              {renderStackedBookings(activeBookings)}
            </CardContent>
          </Card>
        </Grid>

        {/* Redeemed Bookings (Day in the past) */}
        <Grid item>
          <Card className="stacked-section-card fancy-hover">
            <CardHeader
              className="stacked-section-header"
              title={
                <span className="section-title">
                  <FaCheckCircle className="icon-inline" /> Redeemed Bookings
                </span>
              }
            />
            <CardContent>
              {renderStackedBookings(redeemedBookings)}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CustomerBookingPage;
