import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../services/api";
import {
  CircularProgress,
  Typography,
  Alert,
  Box,
  Card,
  CardContent
} from "@mui/material";

const PaymentReturn = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState("Confirming your payment, please wait...");
  const [severity, setSeverity] = useState("info");

  useEffect(() => {
    const confirmPayment = async () => {
      // Extract the resultIndicator from URL params
      const params = new URLSearchParams(location.search);
      const resultIndicator = params.get("resultIndicator");

      if (!resultIndicator) {
        setMessage("Invalid payment response.");
        setSeverity("error");
        return;
      }

      try {
        // Call the confirm-payment API
        const response = await api.post("/api/confirm-payment/", { resultIndicator });
        const data = response.data;

        if (data.message === "Payment confirmed and booking created.") {
          setMessage("Your payment was successful! Your booking has been confirmed.");
          setSeverity("success");
          // Redirect to "/bookings" after 6 seconds
          setTimeout(() => navigate("/bookings"), 6000);
        } else if (data.message === "Payment failed or could not be processed.") {
          setMessage("Your payment failed or could not be processed.");
          setSeverity("error");
          setTimeout(() => navigate("/"), 6000);
        } else {
          setMessage(data.message || "An unexpected response was received.");
          setSeverity("warning");
        }
      } catch (error) {
        console.error("Payment confirmation error:", error);
        setMessage("An error occurred while confirming your payment.");
        setSeverity("error");
        setTimeout(() => navigate("/"), 6000);
      }
    };

    confirmPayment();
  }, [location.search, navigate]);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // Single color background #01759a
        backgroundColor: "#01759a",
        padding: 2
      }}
    >
      <Card
        sx={{
          maxWidth: 500,
          width: "90%",
          textAlign: "center",
          borderRadius: 2,
          boxShadow: 3,
          overflow: "hidden"
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 4
          }}
        >
          {/* Heading Section */}
          <Typography variant="h5" gutterBottom>
            Payment Status
          </Typography>

          {/* Spinner / Loading Indicator */}
          <CircularProgress sx={{ marginY: 2 }} />

          {/* Dynamic Message */}
          <Typography variant="body1" sx={{ marginY: 2 }}>
            {message}
          </Typography>

          {/* Conditional Alerts */}
          {severity === "error" && (
            <Alert severity="error" sx={{ width: "100%", marginY: 1 }}>
              Please try booking again or contact support.
            </Alert>
          )}
          {severity === "success" && (
            <Alert severity="success" sx={{ width: "100%", marginY: 1 }}>
              Your booking has been confirmed!
            </Alert>
          )}
          {severity === "warning" && (
            <Alert severity="warning" sx={{ width: "100%", marginY: 1 }}>
              Please wait or contact support if this persists.
            </Alert>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default PaymentReturn;
