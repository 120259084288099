// src/components/AboutUs.js
import React from 'react';
import {
  FaMountain,
  FaHiking,
  FaHotel,
  FaCalendarAlt,
  FaCompass,
  FaHeart,
  FaLeaf,
  FaHandsHelping,
} from 'react-icons/fa';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="aboutus-page">
      {/* Full-width, 400px banner */}
      <div className="aboutus-banner">
      </div>

      <div className="aboutus-container">
        {/* Mission Section */}
        <section className="aboutus-section">
          <h2 className="section-title">Our Mission</h2>
          <div className="section-content">
            <p>
              At LebAdvisor, our mission is to offer unforgettable experiences in Lebanon through
              carefully curated full-day tours, diverse packages, and exciting outdoor activities.
              We aim to connect travelers with the rich cultural heritage, natural beauty, and
              vibrant life of Lebanon. Whether you are seeking adventure, relaxation, or a deep
              cultural dive, LebAdvisor is your gateway to Lebanon's hidden gems.
            </p>
          </div>
        </section>

        {/* Services Section */}
        <section className="aboutus-section">
          <h2 className="section-title">What We Offer</h2>
          <div className="services-grid">
            <div className="service-card">
              <FaMountain className="service-icon" />
              <h3>Full-Day Tours</h3>
              <p>
                Explore Lebanon's iconic destinations and hidden gems with our expertly guided
                full-day tours, designed to offer a comprehensive experience of the country's beauty.
              </p>
            </div>
            <div className="service-card">
              <FaHiking className="service-icon" />
              <h3>Outdoor Activities</h3>
              <p>
                From hiking and biking to water sports and adventure trails, we offer a wide range
                of outdoor activities that cater to thrill-seekers and nature lovers alike.
              </p>
            </div>
            <div className="service-card">
              <FaHotel className="service-icon" />
              <h3>Packages</h3>
              <p>
                Enjoy carefully crafted packages that bundle together accommodation, activities,
                and more, ensuring a seamless and hassle-free travel experience in Lebanon.
              </p>
            </div>
            <div className="service-card">
              <FaCalendarAlt className="service-icon" />
              <h3>Advanced Booking</h3>
              <p>
                Our state-of-the-art booking system simplifies the process of planning, booking,
                and managing your trips, offering flexibility and convenience at your fingertips.
              </p>
            </div>
          </div>
        </section>

        {/* Values Section */}
        <section className="aboutus-section">
          <h2 className="section-title">Our Values</h2>
          <ul className="values-list">
            <li>
              <FaCompass className="value-icon" />
              <strong>Authenticity:</strong>
              <span>
                We provide experiences that reflect the true essence of Lebanon, connecting travelers with genuine local culture.
              </span>
            </li>
            <li>
              <FaHeart className="value-icon" />
              <strong>Quality:</strong>
              <span>
                We partner with trusted suppliers to ensure the highest standards of service and satisfaction for our customers.
              </span>
            </li>
            <li>
              <FaLeaf className="value-icon" />
              <strong>Sustainability:</strong>
              <span>
                We are committed to promoting sustainable tourism practices that preserve Lebanon's natural and cultural heritage for future generations.
              </span>
            </li>
            <li>
              <FaHandsHelping className="value-icon" />
              <strong>Community:</strong>
              <span>
                We believe in supporting local communities by promoting small businesses and local guides, fostering a deeper connection between travelers and the people of Lebanon.
              </span>
            </li>
          </ul>
        </section>

        {/* Contact Section */}
        <section className="aboutus-section">
          <h2 className="section-title">Contact Us</h2>
          <div className="contact-card">
            <p>
              We are here to assist you in planning your perfect Lebanese adventure. Feel free to reach out with any inquiries.
            </p>
            <p>
              <strong>Email:</strong>
              &nbsp;
              <a href="mailto:info@lebadvisor.com">info@lebadvisor.com</a>
            </p>
            <p>
              <strong>Phone:</strong>
              &nbsp;+961 71 941 100
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
