// src/components/AllPackages.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import Card from '../Card';
import './AllPackages.css';

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@mui/material';

const AllPackages = () => {
  const [packages, setPackages] = useState([]);

  const [themes, setThemes] = useState([]);
  const [types, setTypes] = useState([]);
  const [governorates, setGovernorates] = useState([]);
  const [provinces, setProvinces] = useState([]);

  const [selectedThemes, setSelectedThemes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedGovernorate, setSelectedGovernorate] = useState(0);
  const [selectedProvince, setSelectedProvince] = useState(0);

  const [showAllThemes, setShowAllThemes] = useState(false);
  const [showAllTypes, setShowAllTypes] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [pkgsRes, catsRes, locsRes, subsCatsRes, subsLocsRes] = await Promise.all([
          api.get('/api/all-packages/'),
          api.get('/api/categories/'),
          api.get('/api/locations/'),
          api.get('/api/subcategories/'),
          api.get('/api/sublocations/')
        ]);
        setPackages(pkgsRes.data);
        setThemes(catsRes.data);
        setGovernorates(locsRes.data);
        setTypes(subsCatsRes.data);
        setProvinces(subsLocsRes.data);

        setSelectedThemes([]);
        setSelectedTypes([]);
        setSelectedGovernorate(0);
        setSelectedProvince(0);
      } catch (error) {
        console.error('Failed to fetch data for AllPackages:', error);
      }
    };
    fetchData();
  }, []);

  const handleCardClick = (id) => {
    navigate(`/package-details/${id}`);
  };

  // Toggle theme
  const handleThemeChange = (evt) => {
    const themeId = parseInt(evt.target.value);
    if (selectedThemes.includes(themeId)) {
      setSelectedThemes(selectedThemes.filter(id => id !== themeId));
    } else {
      setSelectedThemes([...selectedThemes, themeId]);
    }
  };

  // Toggle type
  const handleTypeChange = (typeId) => {
    if (selectedTypes.includes(typeId)) {
      setSelectedTypes(selectedTypes.filter(id => id !== typeId));
    } else {
      setSelectedTypes([...selectedTypes, typeId]);
    }
  };

  // Governorate
  const handleGovernorateChange = (evt) => {
    setSelectedGovernorate(parseInt(evt.target.value));
    setSelectedProvince(0);
  };

  // Province
  const handleProvinceChange = (evt) => {
    setSelectedProvince(parseInt(evt.target.value));
  };

  const filteredPackages = packages.filter(pkg => {
    // pkg.subcategories not sub_categories
    // pkg.sublocations not sub_locations

    // 1) Theme
    if (selectedThemes.length > 0) {
      if (!pkg.categories || !selectedThemes.some(tId => pkg.categories.includes(tId))) {
        return false;
      }
    }
    // 2) Type
    if (selectedTypes.length > 0) {
      if (!pkg.subcategories || !selectedTypes.some(sId => pkg.subcategories.includes(sId))) {
        return false;
      }
    }
    // 3) Governorate
    if (selectedGovernorate !== 0) {
      if (!pkg.location || pkg.location.id !== selectedGovernorate) {
        return false;
      }
    }
    // 4) Province
    if (selectedProvince !== 0) {
      if (!pkg.sublocations || !pkg.sublocations.includes(selectedProvince)) {
        return false;
      }
    }
    return true;
  });

  return (
    <div className="packages-container">
      <div className="packages-banner"></div>

      <div className="packages-content">
        {/* Side filter */}
        <div className="packages-filters">
          <h2>Filter Packages</h2>

          {/* Theme => show 3 by default */}
          <div className="filter-group">
            <InputLabel>Theme</InputLabel>
            <FormGroup>
              {themes.slice(0, showAllThemes ? themes.length : 3).map(theme => (
                <FormControlLabel
                  key={theme.id}
                  control={
                    <Checkbox
                      checked={selectedThemes.includes(theme.id)}
                      onChange={handleThemeChange}
                      value={theme.id}
                    />
                  }
                  label={theme.name}
                />
              ))}
            </FormGroup>
            {themes.length > 3 && (
              <Button onClick={() => setShowAllThemes(!showAllThemes)}>
                {showAllThemes ? 'Show Less' : 'Load More'}
              </Button>
            )}
          </div>

          {/* Type => show 3 by default */}
          <div className="filter-group">
            <InputLabel>Type</InputLabel>
            <FormGroup>
              {types.slice(0, showAllTypes ? types.length : 3).map(st => (
                <FormControlLabel
                  key={st.id}
                  control={
                    <Checkbox
                      checked={selectedTypes.includes(st.id)}
                      onChange={() => handleTypeChange(st.id)}
                    />
                  }
                  label={st.name}
                />
              ))}
            </FormGroup>
            {types.length > 3 && (
              <Button onClick={() => setShowAllTypes(!showAllTypes)}>
                {showAllTypes ? 'Show Less' : 'Load More'}
              </Button>
            )}
          </div>

          {/* Governorate */}
          <div className="filter-group">
            <InputLabel>Governorate</InputLabel>
            <FormControl fullWidth>
              <Select
                value={selectedGovernorate}
                onChange={handleGovernorateChange}
              >
                <MenuItem value={0}>All Governorates</MenuItem>
                {governorates.map(loc => (
                  <MenuItem key={loc.id} value={loc.id}>
                    {loc.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {/* Province */}
          <div className="filter-group">
            <InputLabel>Province</InputLabel>
            <FormControl fullWidth>
              <Select
                value={selectedProvince}
                onChange={handleProvinceChange}
              >
                <MenuItem value={0}>All Provinces</MenuItem>
                {provinces.map(sub => (
                  <MenuItem key={sub.id} value={sub.id}>
                    {sub.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        {/* Right side => SEO text + cards */}
        <div className="packages-main">
          <div className="seo-text">
            <h3>Lebanon Packages - Plan Your Perfect Getaway</h3>
            <p>
              Discover comprehensive travel packages that bundle lodging, transport, and 
              curated activities into one seamless experience. From romantic escapes to 
              family-friendly adventures, our packages fit every traveler’s needs.
            </p>
          </div>

          <div className="packages-cards scrollable-row">
            {filteredPackages.map(item => (
              <Card
                key={item.id}
                item={item}
                onClick={() => handleCardClick(item.id)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllPackages;
