// src/components/AllTours.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import Card from '../Card';
import './AllTours.css';

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@mui/material';

const AllTours = () => {
  const [tours, setTours] = useState([]);

  const [themes, setThemes] = useState([]);
  const [types, setTypes] = useState([]);
  const [governorates, setGovernorates] = useState([]);
  const [provinces, setProvinces] = useState([]);

  const [selectedThemes, setSelectedThemes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedGovernorate, setSelectedGovernorate] = useState(0);
  const [selectedProvince, setSelectedProvince] = useState(0);

  const [showAllThemes, setShowAllThemes] = useState(false);
  const [showAllTypes, setShowAllTypes] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [toursRes, catsRes, locRes, subCatsRes, subLocsRes] = await Promise.all([
          api.get('/api/all-tours/'),
          api.get('/api/categories/'),
          api.get('/api/locations/'),
          api.get('/api/subcategories/'),
          api.get('/api/sublocations/')
        ]);
        setTours(toursRes.data);
        setThemes(catsRes.data);
        setGovernorates(locRes.data);
        setTypes(subCatsRes.data);
        setProvinces(subLocsRes.data);

        setSelectedThemes([]);
        setSelectedTypes([]);
        setSelectedGovernorate(0);
        setSelectedProvince(0);
      } catch (error) {
        console.error('Failed to fetch data for AllTours:', error);
      }
    };
    fetchData();
  }, []);

  const handleCardClick = (id) => {
    navigate(`/tour-details/${id}`);
  };

  const handleThemeChange = (evt) => {
    const themeId = parseInt(evt.target.value);
    if (selectedThemes.includes(themeId)) {
      setSelectedThemes(selectedThemes.filter(id => id !== themeId));
    } else {
      setSelectedThemes([...selectedThemes, themeId]);
    }
  };

  const handleTypeChange = (typeId) => {
    if (selectedTypes.includes(typeId)) {
      setSelectedTypes(selectedTypes.filter(id => id !== typeId));
    } else {
      setSelectedTypes([...selectedTypes, typeId]);
    }
  };

  const handleGovernorateChange = (evt) => {
    setSelectedGovernorate(parseInt(evt.target.value));
    setSelectedProvince(0);
  };

  const handleProvinceChange = (evt) => {
    setSelectedProvince(parseInt(evt.target.value));
  };

  const filteredTours = tours.filter(item => {
    // item.subcategories not sub_categories
    // item.sublocations not sub_locations

    // 1) Theme
    if (selectedThemes.length > 0) {
      if (!item.categories || !selectedThemes.some(tId => item.categories.includes(tId))) {
        return false;
      }
    }
    // 2) Type
    if (selectedTypes.length > 0) {
      if (!item.subcategories || !selectedTypes.some(sId => item.subcategories.includes(sId))) {
        return false;
      }
    }
    // 3) Governorate
    if (selectedGovernorate !== 0) {
      if (!item.location || item.location.id !== selectedGovernorate) {
        return false;
      }
    }
    // 4) Province
    if (selectedProvince !== 0) {
      if (!item.sublocations || !item.sublocations.includes(selectedProvince)) {
        return false;
      }
    }
    return true;
  });

  return (
    <div className="tours-container">
      <div className="tours-banner"></div>

      <div className="tours-content">
        <div className="tours-filters">
          <h2>Filter Tours</h2>

          {/* Theme => show 3 default */}
          <div className="filter-group">
            <InputLabel>Theme</InputLabel>
            <FormGroup>
              {themes.slice(0, showAllThemes ? themes.length : 3).map(theme => (
                <FormControlLabel
                  key={theme.id}
                  control={
                    <Checkbox
                      checked={selectedThemes.includes(theme.id)}
                      onChange={handleThemeChange}
                      value={theme.id}
                    />
                  }
                  label={theme.name}
                />
              ))}
            </FormGroup>
            {themes.length > 3 && (
              <Button onClick={() => setShowAllThemes(!showAllThemes)}>
                {showAllThemes ? 'Show Less' : 'Load More'}
              </Button>
            )}
          </div>

          {/* Type => show 3 default */}
          <div className="filter-group">
            <InputLabel>Type</InputLabel>
            <FormGroup>
              {types.slice(0, showAllTypes ? types.length : 3).map(st => (
                <FormControlLabel
                  key={st.id}
                  control={
                    <Checkbox
                      checked={selectedTypes.includes(st.id)}
                      onChange={() => handleTypeChange(st.id)}
                    />
                  }
                  label={st.name}
                />
              ))}
            </FormGroup>
            {types.length > 3 && (
              <Button onClick={() => setShowAllTypes(!showAllTypes)}>
                {showAllTypes ? 'Show Less' : 'Load More'}
              </Button>
            )}
          </div>

          {/* Governorate */}
          <div className="filter-group">
            <InputLabel>Governorate</InputLabel>
            <FormControl fullWidth>
              <Select
                value={selectedGovernorate}
                onChange={handleGovernorateChange}
              >
                <MenuItem value={0}>All Governorates</MenuItem>
                {governorates.map(loc => (
                  <MenuItem key={loc.id} value={loc.id}>
                    {loc.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {/* Province */}
          <div className="filter-group">
            <InputLabel>Province</InputLabel>
            <FormControl fullWidth>
              <Select
                value={selectedProvince}
                onChange={handleProvinceChange}
              >
                <MenuItem value={0}>All Provinces</MenuItem>
                {provinces.map(sub => (
                  <MenuItem key={sub.id} value={sub.id}>
                    {sub.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="tours-main">
          <div className="seo-text">
            <h3>Lebanon Tours - Unforgettable Journeys</h3>
            <p>
              From cultural city visits to scenic mountain routes, our curated tours 
              offer a unique taste of Lebanon’s rich heritage and breathtaking landscapes. 
              Choose your dream itinerary and embark on an adventure of a lifetime.
            </p>
          </div>

          <div className="tours-cards scrollable-row">
            {filteredTours.map(item => (
              <Card
                key={item.id}
                item={item}
                onClick={() => handleCardClick(item.id)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllTours;
