// src/components/SearchResultsPage.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../services/api';
import Card from './Card';
import './SearchResultsPage.css';

const SearchResultsPage = () => {
  const [activities, setActivities] = useState([]);
  const [tours, setTours] = useState([]);
  const [packages, setPackages] = useState([]);

  const locationHook = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(locationHook.search);
    const keyword = params.get('keyword') || '';
    const category = params.get('category') || '';
    const loc = params.get('location') || '';
    const themeId = params.get('theme_id') || '';

    const fetchData = async () => {
      try {
        const response = await api.get('/api/search', {
          params: {
            keyword,
            category,
            location: loc,
            theme_id: themeId,
          },
        });
        setActivities(response.data.activities || []);
        setTours(response.data.tours || []);
        setPackages(response.data.packages || []);
      } catch (error) {
        console.error('Search error:', error);
      }
    };

    fetchData();
  }, [locationHook.search]);

  const handleCardClick = (id, type) => {
    navigate(`/${type}-details/${id}`);
  };

  return (
    <div className="search-results-page">
      <div className="search-results-banner">
      </div>

      <div className="search-results-container">
        {activities.length > 0 && (
          <section className="search-section">
            <h2>Activities</h2>
            <div className="search-cards-row">
              {activities.map((item) => (
                <Card
                  key={item.id}
                  item={item}
                  onClick={() => handleCardClick(item.id, 'activity')}
                />
              ))}
            </div>
          </section>
        )}
        {tours.length > 0 && (
          <section className="search-section">
            <h2>Tours</h2>
            <div className="search-cards-row">
              {tours.map((item) => (
                <Card
                  key={item.id}
                  item={item}
                  onClick={() => handleCardClick(item.id, 'tour')}
                />
              ))}
            </div>
          </section>
        )}
        {packages.length > 0 && (
          <section className="search-section">
            <h2>Packages</h2>
            <div className="search-cards-row">
              {packages.map((item) => (
                <Card
                  key={item.id}
                  item={item}
                  onClick={() => handleCardClick(item.id, 'package')}
                />
              ))}
            </div>
          </section>
        )}

        {activities.length === 0 && tours.length === 0 && packages.length === 0 && (
          <div className="no-results">
            <h2>No results found.</h2>
            <p>Try refining your filters or using a different keyword.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchResultsPage;
